html {
  --defaultDarkColor: #C9E465;
  --defaultBlackColor: #000;
  --defaultGreyColor: #EDEDED;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
}

.lazy_loading {
  background-image: url("./assets/lazyLoader.png");
}

.background_img_property {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.navbar_main {
  background-color: var(--defaultBlackColor);
  position: sticky;
  top: 0;
  z-index: 999
}

.home_main_container {
  width: 83%;
  margin: auto;
  border-radius: 12px;
  background-image: url('./assets//grey_back_img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.common_container {
  width: 80%;
  margin: auto;
  /* border: 1px solid green; */
}

.logo {
  height: 115px;
}

.header_mainDiv {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 100px; */
}

.menus_div {
  display: flex;
  align-items: center;
  gap: 50px;
  height: 100%;
}

.nav_link {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}

.active_link {
  color: var(--defaultDarkColor) !important
}

.header_search {
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  border: none !important;
  height: 45px;
  width: 40%;
}

.header_search .ant-input::placeholder {
  color: #000 !important;
}

.header_search:hover {
  /* background-color: #353535 !important; */
}

.header_search .ant-input-outlined:focus-within {
  /* border: none !important; */
  background-color: #353535 !important;
}

.carousel_img {
  height: 300px !important;
  width: 100% !important;
}

.slider_img {
  width: 100% !important;
}

.blackLayer_caresol {
  position: absolute;
  top: 0;
  content: '';
  background-color: #000;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  border-radius: 19px;
}

.arrows {
  position: absolute;
  top: 40%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0px 12px;
}

.slider_arrow {
  height: 50px;
  cursor: pointer;
}

.album_art {
  cursor: pointer;
  height: 37vh;
  border-radius: 16px;
}

.art_image_section {
  margin-top: 15px;
}

.text_position {
  position: absolute;
  top: 75%;
  left: 10%;
}

.homart_txt {
  color: #000;
  font-size: 1.2rem;
  font-weight: 700;
}

.shop_from_mainDiv {
  margin-top: 90px;
}

.headngs {
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;

}

.line {
  height: 5px;
  background-color: var(--defaultDarkColor);
  width: 30%;
}

.card_title {
  font-size: 1rem;
}

.exculesive_card {
  position: relative;
  box-shadow: 0px 3px 7px #0000004F;
  border-radius: 13px;
  background-color: #f1f1f1;
  padding: 0px;

}


.like_unlike img {
  height: 43px !important;
  cursor: pointer;
}

.like_unlike {
  position: absolute;
  top: 6px;
  left: 0;
  right: 7px;
  text-align: end;
}

.catlouge_leftDiv {
  position: relative;
}

.signature_imgMain_div {
  height: 240px;
  background-color: #000;
  padding: 40px;
  border-radius: 13px;
}

.catlouge_leftDiv {
  background-image: url('./assets/blackLogo.png');
  background-size: contain !important;
  height: 100%;
  width: 100%;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.catlouge_rightCol {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.catlouge_rightDiv {
  padding: 30px 0px 9px 40px;
}

.explore_btn {
  background-color: var(--defaultDarkColor);
  color: var(--defaultBlackColor);
  font-weight: 600;
  padding-inline: 40px;
  font-size: 1rem;
  border-radius: 30px;
  height: 50px;
  margin-top: 113px;
}

.footer_mainDiv {
  background-image: url('./assets/footer.png');
  text-align: center;
}

.footer_image_text img {
  height: 115px !important;
}

.footer_image_text {
  padding-top: 30px;
  height: 100%;
}

.menus_div_footer {
  text-align: center;
}

.menus_div_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
}

.cat_heading {
  margin-bottom: 20px;
}

.nav_link_footer {
  color: var(--defaultBlackColor);
  text-decoration: none !important;
  font-size: 1.2rem;
}

.nav_link_footer:hover {
  color: var(--defaultDarkColor);
}

.footer_txt {
  font-size: 1.2rem;
  margin-top: 8px;
}

.solidApp_logo {
  height: 33px !important;
}

.bottom {
  font-size: 1rem;
  padding: 28px;
  background-image: url('./assets/footer_blackLine.png');
  height: 70px;
  text-align: center;
  color: #fff;
}

.tc_logo {
  height: 33px;
}


.about_div {
  background-image: url('./assets/about_bg.png');
  min-height: 450px;
}

.content {
  font-size: 1.2rem;
  text-align: justify;
  margin-top: 25px;
}

.about_headngs {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.contact_form_div .ant-input::placeholder {
  color: var(--defaultBlackColor) !important;
}

.contact_form_div {
  border-radius: 27px;
  background-color: #EDEDED;
}

.contact_form_input {
  height: 50px;
  background: #fff;
  /* width: 95%; */
  border-radius: 12px;
}

.contact_btn {
  background-color: var(--defaultDarkColor);
  color: var(--defaultBlackColor);
  padding-inline: 40px;
  font-size: 1rem;
  border-radius: 30px;
  height: 40px;
  margin-top: 20px;
  width: 177px;
}

.ant-popover .ant-popover-inner {
  /* background: var(--defaultBlackColor); */
  padding: 0px;
}

.header_popOver {
  padding: 9px 30px 8px 10px;
  border-radius: 6px;
  background-color: var(--defaultBlackColor);
}

.header_popOver_img {
  height: 20px !important;
}

.header_popOver_img_notification {
  height: 25px !important;
}

.art_image_modal {
  height: 350px !important;
  border-radius: 14px;
}

.top_ranking {
  position: absolute;
  top: -38px;
  justify-content: space-between;
  left: -23px;
  text-align: end;
  width: 100%;
  display: flex;
}

.ranking_image {
  height: 80px !important;
}


.sale_price_tag {
  margin-top: 40px;
  position: relative;
}

.sale_price_tag img {
  height: 90px !important;
}

.sale_price_main {
  position: absolute;
  top: 6px;
  left: 0px;
  right: 0px;
  text-align: center;
}

.sale_price {
  font-size: 1.1rem;
  font-weight: 600;
}

.sale_TXT {
  font-weight: 600;
  font-size: 0.8rem;
}

.cart_item {
  position: relative;
  box-shadow: 0px 5px 15px #4848481A;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
}

.cartItemsImage {
  height: 250px !important;

}

.cart_name {
  font-size: 1.1rem;
  font-weight: bold;
}

.delete_div {
  position: absolute;
  top: 2px;
  cursor: pointer;
  right: -13%;
}

.subTotal_btn {
  border: 1px solid #97694F;
  border-radius: 15px;
}

.sub_total {
  font-size: 1.3rem;
  cursor: pointer;
  font-weight: bold;
}

/* auth */
.login_mainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.auth_txt {
  color: var(--defaultDarkColor);
  font-size: 1.3rem;
  font-weight: 800;
}

.login_image {
  height: 70vh !important;
}

.signUp_image {
  height: 90vh !important;
}

.form_div {
  background-color: var(--defaultGreyColor);
  border-radius: 25px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
}

.auth_form_input {
  box-shadow: 0px 3px 7px #00000024;
  border-radius: 15px;
  opacity: 1;
  height: 50px;
  font-weight: 600;
  background-color: #F9F9F9;
  /* width: 85%; */
}

.auth_form_input::placeholder {
  /* color: #000; */
  font-weight: 300 !important;
}

.form_div .ant-input-affix-wrapper>input.ant-input::placeholder {
  /* color: #000; */
}

.auth_btn {
  background-color: var(--defaultDarkColor);
  font-size: 1.1rem;
  font-weight: bold;
  padding-inline: 100px;
  margin-top: 25px;
  border-radius: 25px;
  height: 45px;
}

.social_icon {
  width: 12%;
  cursor: pointer;
}

.sign_upDiv {
  width: 85%;
  margin: auto;
}

.forgot_txt {
  font-size: 1.5rem;
}

.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 1rem;
  width: 3.7rem !important;
  height: 3.7rem !important;
  font-size: 2rem;
  border: none !important;
  box-shadow: 0px 3px 7px #00000024;
  border-radius: 15px !important;
}

.article_btns {
  border-radius: 20px;
  height: 41px;
  font-size: 1rem;
  min-width: 123px;
}


.size_button {
  background: #F4F4F4;
  box-shadow: 0px 2px 3px #00000024;
  min-height: 2.5rem;
}

.product_perticuler_image {
  height: 115px;
  width: 118px;
  background-size: contain !important;
  border-radius: 7px;
}

.product_bigImage {
  height: 45vh;
  border-radius: 12px;
  border: 1px solid rgb(236, 234, 234);
  /* position: relative; */
}


.canvas-container {
  margin: auto !important;
}

.canvas-container canvas {
  width: 100% !important;
}

.canvasContainer {
  margin: auto !important;
}

.tab_txt {
  font-size: 1.2rem;
}

.address_card {
  box-shadow: 0px 3px 10px #00000017;
  border-radius: 18px;
  opacity: 1;
  width: 100% !important;
}

.shipping_add_form {
  width: 60%;
  margin: auto;
}

.add_address_form {
  box-shadow: 0px 3px 10px #00000017;
  border: 1px solid #EBEBEB;
  border-radius: 18px;
  opacity: 1;
  padding-inline: 100px;
}

.detail_side {
  width: 16%;
}

.product_image_summary {
  height: 120px !important;
}

.offer_box {
  /* background-color: #EDEDED; */
  box-shadow: 0px 3px 7px #00000024;
  border-radius: 20px;
  opacity: 1;
}

.cupponCopyBtn {
  width: 120px;
  font-size: 0.9rem;
  /* background-color: var(--defaultDarkColor); */
  border-radius: 20px;
  height: 40px;
}

.apply_special_offer {
  box-shadow: 0px 3px 7px #00000024;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary_cards {
  min-height: 250px;
  max-height: 250px;
  overflow: auto;
}

.ship_sumry_addres {
  font-size: 1.2rem;
  text-align: start;
}

.popOverPages_main {
  width: 75%;
  margin: auto;
}

.homePopOverMain {
  background-color: #EDEDED;
  border-radius: 35px;
  opacity: 1;
}

.leftSide {
  overflow-y: auto;
  margin-left: 20px;
}

.detail_area {
  border-radius: 33px;
}

.order_list {
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
}

.order_detail_page {
  padding-inline: 60px;
}

.order_item {
  box-shadow: 0px 3px 7px #00000024;
  border-radius: 20px;
  opacity: 1;
  background-color: #F9F9F9;
}

.plusIcon_and_upldtxt {
  position: absolute;
  top: 22%;
  left: 0;
  right: 0;
}

.art_uploadImg {
  border: 1px solid #FDE7E8;
  border-radius: 10px;
  height: 130px !important;
  width: 130px !important;
  object-fit: cover;
}

.notification_delte_icon {
  width: 63px !important;
}

.article_list_image {
  border: 1px solid rgb(238, 237, 237);
  height: 250px;
  border-radius: 8px;
}

.category_buttonDiv {
  overflow-x: auto;
}

.color_image {
  border-radius: 50%;
  height: 40px !important;
  width: 40px !important;
}

.adminPrduct_color {
  font-size: 1rem;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.your_desginDiv {
  position: absolute;
  top: 35%;
  left: 24%;
}

.your_desginDiv img {
  cursor: pointer;
  height: 120px !important;
}

.drag_image {
  cursor: grab;
  height: 100%;
}

.increase_decrase {
  height: 35px !important;
}

.product_scroll_container {
  overflow-x: scroll;
  position: relative;
  scroll-behavior: smooth;
  /* Add smooth scrolling behavior */
}

.sizeButtons {
  position: absolute;
  top: 2%;
  right: 2%;
}

.float_menus {
  right: 0.5%;
  position: fixed;
  top: 40%;
  border-radius: 6px;
}

.flot_icon {
  background-color: var(--defaultDarkColor);
  padding: 15px;
  border-radius: 100%;
}

.artist_detailDiv {
  height: 120px;
  width: 120px;
  border-radius: 100%;
}

.artist_webLink {
  color: #000 !important;
}

.artist_webLink:hover {
  color: var(--defaultDarkColor) !important;
}

.notificationModal {
  height: 500px;
  overflow-y: auto;
}

.carousel-item {
  padding: 0 5px;
}

.custom_art {
  height: 30vh;
  width: 25%;
  margin: auto;
}

.custom_art_text {
  width: 35vw;
  margin: auto;
}

.see_all:hover {
  color: var(--defaultDarkColor);
}

.collapse_button {
  background-color: var(--defaultDarkColor);
  height: 37px;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-top: 10px;
}

.article_side_view {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  /* height: 95%; */
  /* width: 350px;
  position: absolute;
  top: 4%;
  right: 4%;
  overflow-y: auto; */
}

.main_option_bar {
  border: 2px solid var(--defaultDarkColor);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.option_txt {
  font-size: 1rem;
}

.side_product {
  min-height: 130px;
  width: 100%;
  background: #9e9b9b;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageParent {
  height: 120px;

}

.side_productImage {
  border-radius: 11px;
  background-size: contain !important;
  height: 100%;
}

.new_artImage {
  height: 80px !important;
  width: 120px !important;
}

.product_mainImg {
  height: 55vh;
  border: 1px solid var(--defaultDarkColor);
  border-radius: 12px;
  width: 45%;
  /* background: #9e9b9b; */
}

.product_Big {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  ;
}

.siderbarPopOver {
  padding: 9px 30px 8px 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: 510px;
  border: 2px solid #cccccc;
}

.Porduct_color {
  font-size: 1rem;
  height: 45px;
  width: 45px;
  border-radius: 12px;
}

.siderArtModalArts {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 135px;
  /* width: 140px; */
  border-radius: 10px;
}

.overlay_arts {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100px;
  width: 130px;
  border-radius: 10px;
  overflow: auto;
}

.artShowingDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-X: auto;
  overflow-y: hidden;
}

.selected_art_carousel_item {
  padding: 0 2px;
}

.carousel-item {
  padding: 0 5px;
}

.canvas-wrapper {
  border: 1px solid var(--defaultDarkColor);
  /* border-radius:8px; */
}

.leftSideDiv {
  /* width: 700px; */
}

.horizontal_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-inline: 12px;
  font-size: 25px;
}

.vertical_text {
  display: inline-block;
  white-space: pre;
  writing-mode: vertical-rl;
  text-orientation: upright;
  /* width: 100%; */
  height: 100%;
  font-size: 25px;
}

.text_preview {
  border: 1px solid var(--defaultDarkColor);
  border-radius: 12px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  overflow: auto;
}

.desgin_main_part {
  width: 80%;
  margin: auto;
  /* display: flex; */
  /* flex-direction: row-reverse; */
}