html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: lightgrey;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.ant-modal {
  margin: 62px auto !important;
}

.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
  text-align: left !important;
  margin-top: 5px !important;
}

.ant-modal .ant-modal-content {
  border-radius: 17px !important;
  padding: 13px 24px !important;

}

.ant-card .ant-card-body {
  padding: 0 !important;

}

.verification .anticon {
  font-size: 1.3rem !important;
  color: #C9E465 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 12px 11px 2px !important;
}

.ant-collapse {
  background-color: #EDEDED !important;
  border: none !important;
  box-shadow: 0px 3px 4px 1px #dbdbdb;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse .ant-collapse-content {
  background-color: #EDEDED !important;
}

.ant-input-borderless[disabled] {
  color: #000 !important;
}

.ant-checkbox .ant-checkbox-inner {
  width: 19px !important;
  height: 20px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #000;
}

.ant-checkbox .ant-checkbox-inner:after {
  border-color: #000 !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #C9E465 !important;
  background-color: #C9E465 !important;
}

.ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 1px solid grey !important;
}

.ant-upload-list-item-container {
  height: 142px !important;
  width: 145px !important;
}
.customArtUpload{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.customArtUpload .ant-tooltip {
  display: none !important;
}
.detail_area .ant-tooltip {
  display: none;
}
span.anticon.anticon-eye {
  display: none;
}

/* carousel */
.ant-carousel .slick-dots li.slick-active button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 100% !important;
  border: 2px solid var(--defaultDarkColor) !important;
  background-color: var(--defaultDarkColor) !important;
}

.ant-carousel .slick-dots li button {
  width: 15px !important;
  height: 15px !important;
  background-color: var(--defaultDarkColor) !important;
  border: 2px solid var(--defaultDarkColor) !important;
  border-radius: 100% !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: 14px !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: 22px !important;
}

.text_editor .ant-select-selector {
  border: none !important;
  background: none !important;
}